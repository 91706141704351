$backgroundcolor: rgb(159, 241, 250);
$selectablecolor: rgb(114, 114, 145);
$selectabletextcolor: rgb(255, 255, 255);
$activecolor: rgb(60, 61, 61);
$componentbackgroundcolor: rgb(255, 255, 255);
$textcolor: rgb(83, 81, 81);

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $backgroundcolor;
	background-attachment: fixed;
	background-color: #ffffff;
	background-image: linear-gradient(166deg, rgb(114, 114, 145) 5%, #9ff1fa 100%);

	background-blend-mode: multiply, overlay, difference, difference, normal;
}

th.MuiTableCell-head {
	font-weight: bold;
}

div.MuiDataGrid-columnHeaderTitle {
	font-weight: bold;
}

.unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.aimaps-logo {
//	height: 50px;
	width: 150px;
	margin: 5px 5px 5px;
}

.aimaps-welcome-logo {
	height: 0.75em;
}

.welcome {
	color: white;
	font-style: bold;
	text-transform: uppercase;
	font-size: 4em;
}

.nav-bar {
	background-color: $componentbackgroundcolor;
	position: sticky;
	top: 0;
	margin-top: 0;
	border-bottom: solid 5px $backgroundcolor;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
}

.input {
	display: none;
}

.link {
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
	margin-right: 0;
	color: #041638;
	padding: 0px 10px;
	color: #ffffff;

}

.link:hover {
	color: #9ff1fa;
	opacity: 0.8;
}

.date-span {
	margin: 5px 0 5px 0;
}

a:link {
	text-decoration: none;
}


a:visited {
	text-decoration: none;
}


a:hover {
	text-decoration: none;
}


a:active {
	text-decoration: none;
}

.photo {
	border: 1px solid rgb(142, 137, 137);
	/* Gray border */
	border-radius: 4px;
	/* Rounded border */
	padding: 5px;
	/* Some padding */
	width: 150px;
	/* Set a small width */
}

.photo:hover {
	box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.clock {
	position: absolute;
	font-size: 17px;
	right: 240px;
}

.time-heatmap {
	font-size: 12px;
	background-color: $componentbackgroundcolor;
}

#file-upload-form {
	height: 8rem;
	width: 14rem;
	max-width: 100%;
	text-align: center;

}

#file-upload-input {
	display: none;
}

#file-upload-label {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 2px;
	border-radius: 1rem;
	border-style: dashed;
	border-color: #cbd5e1;
	background-color: #f8fafc;
}

#file-upload-label:hover {
	cursor: pointer;
}

#file-upload-label.drag-active {
	background-color: #ffffff;
}

#drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

button:not(.MuiButton-contained) {
	//background-color: rgb(143, 143, 159) !important;
	//color: #ffffff !important;
}

button:hover {
	color: #9ff1fa !important;
}

.MuiToggleButtonGroup-root>.Mui-selected {
	color: #9ff1fa !important;
}

.loginbutton {
	font-size: 2em !important;
}

.adminbutton {
	//background-color: rgb(143, 143, 159);
	color: #ffffff;
}

.adminbutton:hover {
	color: #9ff1fa;
}

.search-bar {
	border-radius: 5px;
	background-color: #ffffff;
}